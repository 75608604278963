import "./index.css";

import Logo from "./components/Logo/Logo";
import Content from "./components/Content/Content";
import Video from "./components/Video/Video";

function App() {
  return (
    <div className="App">
      <section>
        <Logo />
        <Content />
      </section>

      <section >
      <Video />

      </section>
    </div>
  );
}

export default App;
