import "../../index.css";
import { Icon } from "@iconify/react";

import dots from "../../Midia/dots.png";
import video from "../../Midia/legendado.mp4";
import eciene from "../../Midia/eciene.jpg";

function Video() {
  return (
    <div className="Video">
      <div className="videoPresentation">
        <img alt="Dots" src={dots} />
        <div className="videoLinks">
          <div className="sizeVideo">
            <video src={video} controls preload poster={eciene} />
          </div>

          <a
            target="_blank"
            href="https://chat.whatsapp.com/GMohfMM7Uz7D3rKfMFfmRc"
          >
            <p> Faça sua pré-inscrição em nosso grupo!</p>
            <Icon icon="akar-icons:whatsapp-fill" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Video;
