import "../../index.css";

import { Icon } from "@iconify/react";
import emailjs from "emailjs-com";

function Content() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kgvypor",
        "template_k3zhc9j",
        e.target,
        "user_Lk08AzNAVBQTDKdYLWOBD"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("E-mail enviado com sucesso");
        },
        (error) => {
          console.log(error.text);
          alert("Verifique se os campos foram preenchidos corretamente");
        }
      );
  }

  return (
    <div className="Content">
      <div className="contentSection">
        <h2>
          Você vai aprender a ser <br /> uma excelente anfitriã
        </h2>

        <div className="sizeH2Input">
          <h3>
            Descubra tudo sobre etiqueta, decoração e mesa posta, aprendendo na
            prática como oferecer um jantar elegante com as louças da sua
            própria casa.
          </h3>
          <div className="inputEmail">
            <Icon
              icon="mdi-light:email"
              className="iconEmail"
              style={{ fontSize: "25px" }}
            />

            <form onSubmit={sendEmail}>
              <div className="linksForm">
                <input
                  required
                  type="email"
                  placeholder="Insira o seu e-mail aqui"
                  name="email"
                  style={{ background: "#FFF", color: "#000" }}
                />
              </div>
              <div className="inputButton">
                <input type="submit" value="enviar" />
              </div>
            </form>
          </div>
        </div>

        <div className="icons">
          <a
            href="https://www.instagram.com/ec_encantoecharme/"
            target="_blank"
          >
            <Icon icon="ant-design:instagram-filled" />
          </a>
          <a href="https://www.facebook.com/Ecienemesaposta/" target="_blank">
            <Icon icon="ant-design:facebook-filled" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Content;
