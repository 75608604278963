import "../../index.css";
import maratona from "../../Midia/maratona.png";
import maratonaMesaPosta from "../../Midia/maratonaMesaPosta.png";

function Logo() {
  return (
    <div className="LogoSection">
      <nav>
        <img alt="Logo - Maratona Mesa Posta" src={maratona} />
        <img alt="Logo - Maratona Mesa Posta 2" src={maratonaMesaPosta}/>
      </nav>
    </div>
  );
}

export default Logo;
